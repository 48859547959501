import React from 'react'
import { withStyles, Typography, Grid, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'
import { BarChart, SectionTitle, getChartData } from 'gatsby-components'

const AssessmentPillars = ({
  theme,
  classes,
  assessment,
  assessmentData,
  pillarColors,
}) => {
  if (!assessmentData) return null
  const { t } = useTranslation()
  const chartData = getChartData(assessment, assessmentData, pillarColors)
  return (
    <div className={classes.section} data-testid="assessment__model-areas">
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="h4" gutterBottom>
            {t(
              'To navigate within the assessment please click on an area of the model'
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {assessment.pillars.map((pillar, pillarIndex) => {
          const pillarColor = pillarColors[pillarIndex]
          return (
            <Grid
              key={pillar.name}
              item
              xs
              container
              spacing={3}
              direction="column"
            >
              <Grid item>
                <SectionTitle barColor={pillarColor}>
                  {t(pillar.name)}
                </SectionTitle>
              </Grid>
              {pillar.criteria.map(criterion => (
                <Grid item key={criterion.name}>
                  <Typography
                    component={assessmentData.id ? Link : null}
                    to={
                      assessmentData.id
                        ? `/assessment/${assessment.key}/${pillar.key}/${criterion.key}#${assessmentData.id}`
                        : null
                    }
                    variant="h3"
                    display="block"
                    gutterBottom
                    style={{ color: pillarColor }}
                  >
                    {t(criterion.name)}
                  </Typography>
                  {criterion.parts.length > 0 &&
                    criterion.key !== 'stakeholder-impact' &&
                    criterion.key !== 'strategic-performance' &&
                    assessment.matrixType !== 'basic' &&
                    assessment.key !== 'questionnaire' && (
                      <Box>
                        {criterion.parts.map(
                          ({ tables: [{ name }] }, index) => (
                            <Typography
                              key={`link-${index}`}
                              component={Link}
                              to={`/assessment/${assessment.key}/${
                                pillar.key
                              }/${criterion.key}/${index + 1}#${
                                assessmentData.id
                              }`}
                              variant="h3"
                              className={classes.sectionPart}
                            >
                              {name.split(':')[0]}
                            </Typography>
                          )
                        )}
                      </Box>
                    )}
                </Grid>
              ))}
            </Grid>
          )
        })}
        {assessment.pillars.length > 0 && (
          <Grid
            key="scoring-summary"
            item
            xs
            container
            spacing={3}
            direction="column"
          >
            <Grid item xs>
              <SectionTitle barColor={theme.palette.primary.dark} gutterBottom>
                {t('Scoring Summary')}
              </SectionTitle>
              <div className={classes.chart}>
                <BarChart chartData={chartData} />
              </div>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

const styles = theme => ({
  section: {
    margin: theme.spacing(3, 0),
  },
  sectionPart: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(2),
  },
  chart: {
    marginLeft: '4px',
  },
})

export default withStyles(styles, { withTheme: true })(AssessmentPillars)
